import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPrint,
  faDesktop,
  faNewspaper,
  faTv,
  faMobile,
  faFileInvoice,
} from '@fortawesome/pro-light-svg-icons'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "marketing/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    office: file(relativePath: { eq: "marketing/office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    consistent: file(relativePath: { eq: "marketing/consistent.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    crm: file(relativePath: { eq: "marketing/crm.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    iphone: file(relativePath: { eq: "marketing/iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    log: file(relativePath: { eq: "marketing/log.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    trackable: file(relativePath: { eq: "marketing/trackable.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    loop: file(relativePath: { eq: "marketing/loop.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    aus: file(relativePath: { eq: "share/aus.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class MarketingPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.6"
                style={{ height: '600px' }}
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'paint-brush']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />

                  <HeaderTitle>
                    Execute winning multi-media sales strategies
                  </HeaderTitle>

                  <HeaderBody>
                    Realhub does the heavy lifting behind the scenes which means
                    less stress during booking, designing and marketing. Anyone
                    can be an expert and market property with Realhub.
                  </HeaderBody>

                  <TypeFormButton text="MORE INFO" />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <BannerH4>
                  Build <strong>an entire campaign</strong> for{' '}
                  <strong>million dollar properties</strong>, in minutes, with
                  team collaboration and vendor inclusion.
                </BannerH4>
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  image={data.crm.childImageSharp.fluid}
                  title="Work less, create more. Marketing automation that assists your team."
                  descWidth="50%"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong
                        >{`The boring bits have been done for you with {smart_data} tags that auto-populate your information, so you can just add the final touches.`}</strong>
                      </Paragraph>
                      <Paragraph>
                        It’s your own data, you should have quick and easy
                        access to communication between your CRM and your
                        marketing platform. With everything, your CRM makes
                        available to us, we can auto-populate CRM stored data
                        straight into your templates. Extending to vendors (via
                        some suppliers) and including automatic pre-filling of
                        agent and listing data accessible inside Realhub.
                      </Paragraph>
                      <Paragraph>
                        <strong>
                          National property data, quick select and replace.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Use your own properties, or properties available across
                        data suppliers to populate design content like market
                        updates, OFI lists, open home comparable sheets, recent
                        sales DLs and more. All your data, and the entire
                        countries sales history at the touch of a button without
                        another data subscription.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="500px"
                opacity="0.4"
                background={data.consistent.childImageSharp.fluid}
              >
                <ContentWrapper direction="row" style={{ margin: '30px 0' }}>
                  <Icons>
                    <Icon icon={faPrint} />
                    <Icon icon={faDesktop} />
                    <Icon icon={faNewspaper} />
                    <Icon icon={faTv} />
                    <Icon icon={faMobile} />
                    <Icon icon={faFileInvoice} />
                  </Icons>

                  <IntroRow
                    descWidth="50%"
                    title="Create consistent marketing collateral across every channel."
                    content={
                      <Fragment>
                        <Paragraph>
                          Get the most from your marketing efforts, deliver
                          marketing that crushes the competition across locally
                          distributed print, national publication networks and
                          the entire scope of digital marketing from social
                          media to lead generating documents.
                        </Paragraph>
                        <Paragraph>
                          A multi-channelled approach across digital and print
                          creates powerful recognition, trust and familiarity
                          that increases your ability to sell more, and list
                          more.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.iphone.childImageSharp.fluid}
                  title="Built for ease of use, faster and more accessible"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          No steep learning curves, speed up your entire team
                          with technology that makes you more efficient.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Built out of necessity to fill a gaping hole in the
                        industry. Realhub’s automation and usability is powering
                        thousands of agencies marketing efforts daily.
                      </Paragraph>
                      <Paragraph>
                        Comparable components, re-using saved artworks smart
                        automation of data population and building print and
                        digital campaigns in seconds. Your team will never have
                        worked so efficiently.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  reverse
                  image={data.log.childImageSharp.fluid}
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Follow artwork progress, from creation to delivery of
                          marketing materials.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        It’s only natural to make mistakes now and then. Team
                        accountability and transparency is important, if a
                        mistake is made it’s not about throwing blame around
                        it’s important to direct, train and manage your team to
                        improve for next time.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.trackable.childImageSharp.fluid}
                  title="One trackable source of truth for your whole campaign."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Eliminate risky email proofing and approvals. Powerful
                          team collaboration.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        You don’t know if someone is approving the current
                        artwork or one from three revisions ago. There’s a huge
                        room for error with email proofing, and when you’re
                        working with artworks for newspaper or by the thousand
                        in off-set printing, the cost of a mistake becomes
                        astronomical.
                      </Paragraph>
                      <Paragraph>
                        Don’t risk making a huge mistake, use our in-built
                        proofing and artwork approval workflows to ensure the
                        right version gets approved before sending to suppliers.
                      </Paragraph>
                      <Paragraph>
                        Finally, approvals and commenting both public (with the
                        vendor) and privately (within your team) in the same
                        place as the artworks. The oversight a manager needs to
                        keep an eye on the entire marketing efforts of an
                        office, available now.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  reverse
                  image={data.loop.childImageSharp.fluid}
                  title="Stay in the loop with your team, and the vendors"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Real-time collaboration between multiple staff,
                          offices and teams.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Open up a world of efficient and relevant communication
                        between your entire team and vendors with our
                        system-wide conversation functionality.
                      </Paragraph>
                      <Paragraph>
                        Get proofs signed off on faster, invite the vendor to
                        contribute to the conversation and eliminate nasty
                        mistakes that can occur with email proofing and
                        communication.
                      </Paragraph>
                      <Paragraph>
                        Save artworks before they go to print with the most up
                        to date proofs being a click away before approval.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="500px"
                opacity="0.5"
                background={data.office.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    title={
                      <strong>
                        Lowered costs of training, production and access
                        anywhere collaboration.
                      </strong>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          You can now reduce the cost of artwork production, and
                          anyone in your office, any staff member with any level
                          of experience will be creating your marketing in
                          minutes. With this reduction in cost you’ll be able to
                          scale larger, growing beyond the typical confines of
                          your team size by using tools that make the business
                          operate faster and more efficiently.
                        </Paragraph>
                        <Paragraph>
                          The whole team can collaborate together on artworks
                          with digital mobile friendly proofing, even when
                          no-ones in the office.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  reverse
                  descWidth="50%"
                  image={data.aus.childImageSharp.fluid}
                  title="Changing specs, growing brand libraries, only the fastest support can keep up."
                  content={
                    <Fragment>
                      <Paragraph>
                        Across newspaper specs, provider requirements and
                        digital platforms constantly changing their optimal
                        sizes and formats it can be quite difficult to ensure
                        you’re always creating your marketing to the right
                        specifications.
                      </Paragraph>
                      <Paragraph>
                        Having support that's operating here on the ground means
                        you’ll always have the best help at hand when you need a
                        quick change or update done.
                      </Paragraph>
                      <Paragraph>
                        With the rate of specification changes, and the rolling
                        out of new designs and marketing strategies by brands
                        you can’t guarantee a template will always be perfect.
                        But having the support that cares, and can quickly
                        resolve your issues will save your day every time.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>every marketing product</strong> in the{' '}
                  <strong>one place</strong>, and
                  <strong> save hours</strong> communicating and tracking entire
                  campaigns.
                </BannerH4>

                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>

              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default MarketingPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Icons = styled.div`
  display: flex;
  width: 100%;
  margin-right: 30px;
  height: 100%;

  ${customMedia.greaterThan('mobile')`
    width: 25px;
    flex-direction: column;
    align-items: center;
  `};
`

const Icon = styled(FontAwesomeIcon)`
  width: 25px;
  height: 25px;
  font-size: 24px;
  margin-right: 15px;
  margin-bottom: 10px;

  ${customMedia.greaterThan('mobile')`
    margin-bottom: 25px;
  `};
`
